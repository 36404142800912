import logoLink from '../resources/roche.png';
import companyLogoLink from '../resources/roche.svg';
import footerLogoLink from '../resources/roche_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#0B41CD',
    panel_highlight: '#0B41CD',
    chat_background_right: '#F0F4FD',
    chat_background_left: '#EAE8E5',

    active: '#0B41CD',
    inactive: '#C40000',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#F0F4FD',

    phonebook_dropdown: '#F0F4FD',
    phonebook_dropdown_svg: '#000000',

    primary_light: '#F0F4FD',
    primary: '#0B41CD',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#C40000',
    text_on_secondary: 'white',
    focus_active: '#C40000',

    red: '#C40000',
    green: '#006D5E',

    connecting: '#999999',
    connected: '#006D5E',
    aborted: '#C40000',

    font_color: '#000000',
    link_color: '#0B41CD',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: '#999999',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#6084E2',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Roche';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
